<template>
  <en-drawer
    :model-value="modelValue"
    :title="`${form.data.id ? '编辑' : '新建'}活动`"
    direction="btt"
    @close="$emit('update:model-value', false)"
    size="80%"
  >
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-4 gap-x-6">
      <en-form-item label="活动编号">
        <en-input :model-value="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="活动名称" prop="name">
        <en-input v-model="form.data.name" :disabled="form.data.status?.code === 'I'"></en-input>
      </en-form-item>
      <en-form-item label="活动简介" prop="intro">
        <en-input v-model="form.data.name" :disabled="form.data.status?.code === 'I'"></en-input>
      </en-form-item>
      <en-form-item label="活动有效期" prop="startDate">
        <en-date-picker
          v-model:start="form.data.startDate"
          v-model:end="form.data.endDate"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="拼团人数" prop="numberOfPeople">
        <en-input-number v-model="form.data.numberOfPeople" :disabled="form.data.status?.code === 'I'" class="flex-1 mr-2"></en-input-number>
        <span>人团</span>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code === 'GP'" label="起订数量">
        <en-input-number v-model="form.data.minCount" :min="1" :disabled="form.data.status?.code === 'I'" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="活动类型">
        <select-maintain
          v-model="form.data.type"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['MGPTYPE']),
            convert: (data) => data.filter((item) => (store.accessRightsHash.CLOUD_TENANT_MALL_GROUP_PURCHASE ? item : item.code !== 'RP'))
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="是否手动关闭订单">
        <select-maintain
          v-model="form.data.orderClosedManually"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="是否填写车牌号">
        <select-maintain
          v-model="form.data.plateNoRequired"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="是否填写手机号">
        <select-maintain
          v-model="form.data.cellphoneRequired"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="是否填写地址">
        <select-maintain
          v-model="form.data.addressRequired"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="是否填写姓名">
        <select-maintain
          v-model="form.data.nameRequired"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="是否开发票">
        <select-maintain
          v-model="form.data.invoiceRequired"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="支持卡券赠送" prop="sendingCouponInstanceRedPackAllowed">
        <select-maintain
          v-model="form.data.sendingCouponInstanceRedPackAllowed"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="支持退款" prop="refundable">
        <select-maintain
          v-model="form.data.refundable"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="退款扣除" prop="deduction">
        <en-input-rate v-model="form.data.deduction" :disabled="form.data.status?.code === 'I'" class="w-full"></en-input-rate>
      </en-form-item>
      <en-form-item label="退款时间">
        <en-input v-model="form.data.refundDays" :disabled="form.data.status?.code === 'I'">
          <span slot="append">天</span>
        </en-input>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="是否显示已售订单份数">
        <select-maintain
          v-model="form.data.ordersVisible"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="收款商户" prop="merchantReceiver">
        <select-maintain
          v-model="form.data.merchantReceiver"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['OLPMPSRTP']),
            convert: (data) => data.filter((m) => m.code !== 'AGENCY' && m.code !== 'WECHATUSER')
          }"
          :props="{ label: 'message', value: '' }"
          @change="form.merchantReceiver.change"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="支持红包">
        <select-maintain
          v-model="form.data.sendRedPack"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type?.code !== 'RP'" label="支持红包">
        <select-maintain
          v-model="form.data.sendRedPack"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="form.data.status?.code === 'I'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="活动图片" class="col-span-4">
        <upload-maintain v-model="form.data.imgUrl" dir="group" :disabled="form.data.status?.code === 'I'"></upload-maintain>
      </en-form-item>
      <en-form-item label="活动详情" class="col-span-3">
        <quill-maintain v-model="form.data.detail" dir="group" :disabled="form.data.status?.code === 'I'"></quill-maintain>
      </en-form-item>
    </en-form>

    <div class="p-5 flex flex-col gap-5">
      <div class="w-full flex items-center justify-between">
        <span class="text-lg font-weight-500">活动套餐</span>
        <en-badge :value="form.data.mallCombos?.length">
          <en-button type="primary" :disabled="form.data.status?.code === 'I'" @click.stop="form.mallCombos.add.click">添加套餐</en-button>
        </en-badge>
      </div>
    </div>

    <div class="p-5 flex flex-col gap-5">
      <div class="w-full flex items-center justify-between">
        <span class="text-lg font-weight-500">赠送卡券</span>
        <en-badge :value="form.data.mallGroupPurchaseRewards?.length">
          <en-button type="primary" :disabled="form.data.status?.code === 'I'" @click.stop="form.mallGroupPurchaseRewards.add.click">
            添加卡券
          </en-button>
        </en-badge>
      </div>

      <en-table :data="form.data.mallGroupPurchaseRewards">
        <en-table-column label="操作" width="180">
          <template #default="{ $index }: { $index: number }">
            <en-button type="primary" link :disabled="form.data.status?.code === 'I'" @click="form.mallGroupPurchaseRewards.delete.click($index)">
              删除
            </en-button>
          </template>
        </en-table-column>
        <en-table-column label="卡券名称" prop="mallCoupon.name" width="200">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            <select-maintain
              v-model="row.mallCoupon"
              :ajax="{ action: 'GET /enocloud/mall/coupon', params: (params, value) => (params.payload = { name: value, statusCode: 'A' }) }"
              :props="{
                label: 'name',
                value: '',
                disabled: (option) => Boolean(form.data.mallGroupPurchaseRewards?.find((item) => item.mallCoupon?.id === option.id))
              }"
              value-key="id"
              remote
              :disabled="form.data.status?.code === 'I'"
              class="w-full"
            ></select-maintain>
          </template>
        </en-table-column>
        <en-table-column label="卡券类型" prop="mallCoupon.type.message" width="200"></en-table-column>
        <en-table-column label="面值" prop="mallCoupon.faceValue" width="200"></en-table-column>
        <en-table-column label="满减金额" prop="mallCoupon.lowerLimitAmount"></en-table-column>
        <en-table-column label="折扣">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            {{ row.mallCoupon && row.mallCoupon.type && row.mallCoupon.type.discountMethod.code === 'P' ? row.mallCoupon.discountValue : 0 }}
          </template>
        </en-table-column>
        <en-table-column label="抵扣金额">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            {{ row.mallCoupon && row.mallCoupon.type && row.mallCoupon.type.discountMethod.code === 'F' ? row.mallCoupon.discountValue : 0 }}
          </template>
        </en-table-column>
        <en-table-column label="项目" prop="mallCoupon.maintenance.name"></en-table-column>
        <en-table-column label="配件" prop="mallCoupon.goods.name"></en-table-column>
        <en-table-column label="有效期类型" width="120">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            <select-maintain
              v-model="row.expireType"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['EXPTYPE']) }"
              :props="{ label: 'description', value: '' }"
              value-key="code"
              :disabled="form.data.status?.code === 'I'"
              class="w-full"
              @change="form.mallGroupPurchaseRewards.expireType.change(row)"
            ></select-maintain>
          </template>
        </en-table-column>
        <en-table-column label="有效期" width="150">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            <en-date-picker
              v-if="row.expireType?.code === 'F'"
              v-model:model-value="row.startDate"
              v-model:end="row.endDate"
              :disabled="form.data.status?.code === 'I'"
              class="w-full"
            ></en-date-picker>
            <en-input v-if="row.expireType?.code === 'D'" v-model="row.validityDays" :disabled="form.data.status?.code === 'I'"></en-input>
          </template>
        </en-table-column>
        <en-table-column label="本次发放总量" prop="totalCount">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            <en-input-number v-model="row.totalCount" :min="0" :disabled="form.data.status?.code === 'I'" class="w-full"></en-input-number>
          </template>
        </en-table-column>
        <en-table-column label="单次领取量" prop="count">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseRewardDto'] }">
            <en-input-number v-model="row.count" :min="0" :disabled="form.data.status?.code === 'I'" class="w-full"></en-input-number>
          </template>
        </en-table-column>
      </en-table>
    </div>

    <div v-if="form.data.type?.code !== 'RP'" class="p-5 flex flex-col gap-5">
      <div class="w-full flex items-center justify-between">
        <span class="text-lg font-weight-500">预约时间</span>
        <en-badge :value="form.data.mallGroupPurchaseReservations?.length">
          <en-button type="primary" :disabled="form.data.status?.code === 'I'" @click.stop="form.mallGroupPurchaseReservations.add.click">
            添加预约
          </en-button>
        </en-badge>
      </div>

      <en-table :data="form.data.mallGroupPurchaseReservations">
        <en-table-column label="操作">
          <template #default="{ $index }: { $index: number }">
            <en-button
              type="primary"
              link
              :disabled="form.data.status?.code === 'I'"
              @click="form.mallGroupPurchaseReservations.delete.click($index)"
            >
              删除
            </en-button>
          </template>
        </en-table-column>
        <en-table-column label="预约时间" prop="reservation">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseReservationDto'] }">
            <select-maintain
              v-model="row.reservation"
              :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['MGPRSV']) }"
              :props="{ label: 'name', value: 'name' }"
              allow-create
              :disabled="form.data.status?.code === 'I'"
              class="w-full"
            ></select-maintain>
          </template>
        </en-table-column>
        <en-table-column label="数量" prop="maxCount">
          <template #default="{ row }: { row: EnocloudMallDefinitions['MallGroupPurchaseReservationDto'] }">
            <en-input-number v-model="row.maxCount" :disabled="form.data.status?.code === 'I'" class="w-full"></en-input-number>
          </template>
        </en-table-column>
      </en-table>
    </div>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click" :disabled="form.data.status?.code === 'I'">确定</button-ajax>
    </template>
  </en-drawer>

  <en-drawer v-model="mallCombos.visible" title="添加套餐" direction="btt" size="90%">
    <div class="flex flex-col gap-5">
      <div v-for="(item, index) in form.data.mallCombos" class="border border-gray-300 flex flex-col p-8">
        <en-form class="grid grid-cols-4 gap-x-6">
          <en-form-item label="套餐名称">
            <en-input v-model="item.name"></en-input>
          </en-form-item>
          <en-form-item label="套餐简介">
            <en-input v-model="item.intro"></en-input>
          </en-form-item>
          <en-form-item label="原价">
            <en-input-number v-model="item.originalPrice" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item v-if="form.data.type?.code === 'RV'" label="定金">
            <en-input-number v-model="item.depositPrice" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item v-if="form.data.type?.code === 'GP'" label="开团价格" prop="sellPrice">
            <en-input-number v-model="item.sellPrice" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item v-if="form.data.type?.code === 'RV' || form.data.type?.code === 'RP'" label="套餐价格" prop="sellPrice">
            <en-input-number v-model="item.sellPrice" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item v-if="form.data.type?.code === 'GP'" label="参团价格" prop="favourablePrice">
            <en-input-number v-model="item.favourablePrice" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item v-if="form.data.type?.code === 'GP'" label="成团返利">
            <en-input-number v-model="item.rebate" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item label="套餐成本" prop="cost">
            <en-input-number v-model="item.cost" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item label="上架数量" prop="count">
            <en-input-number v-model="item.count" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item v-if="form.data.type?.code !== 'PM'" label="每人限定数量">
            <en-input-number v-model="item.maxCountPerCustomer" :min="0" class="w-full"></en-input-number>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="item.comment"></en-input>
          </en-form-item>
          <en-form-item label="套餐图片" class="col-span-4">
            <upload-maintain v-model="item.imgUrls" multiple dir="wechat"></upload-maintain>
          </en-form-item>
        </en-form>

        <div class="flex flex-col gap-5">
          <div class="flex items-center gap-2">
            <span>卡券</span>
            <select-maintain
              :ajax="{ action: 'GET /enocloud/mall/coupon', params: (params, value) => (params.payload = { name: value, statusCode: 'A' }) }"
              :props="{ label: 'name', value: '', disabled: (option: EnocloudMallDefinitions['MallCouponDto']) => Boolean(form.data.mallCombos?.[index].mallComboCoupons?.find(item => item.id === option.id)) }"
              value-key="id"
              @change="form.mallCombos.mallComboCoupons.add.change(index, $event as EnocloudMallDefinitions['MallCouponDto'])"
            ></select-maintain>
          </div>

          <en-table :data="item.mallComboCoupons">
            <en-table-column label="操作" width="180">
              <template #default="{ $index }: { $index: number }">
                <en-button type="primary" link @change="form.mallCombos.mallComboCoupons.delete.click(index, $index)">删除</en-button>
              </template>
            </en-table-column>
            <en-table-column label="卡券名称" width="200">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                <select-maintain
                  v-model="row.mallCoupon"
                  :ajax="{ action: 'GET /enocloud/mall/coupon', params: (params, value) => (params.payload = { name: value, statusCode: 'A' }) }"
                  :props="{ label: 'name', value: '', disabled: (option: EnocloudMallDefinitions['MallCouponDto']) => Boolean(form.data.mallCombos?.[index].mallComboCoupons?.find(item => item.id === option.id)) }"
                  value-key="id"
                  class="w-full"
                ></select-maintain>
              </template>
            </en-table-column>
            <en-table-column label="卡券类型" prop="mallCoupon.type.message" width="200"></en-table-column>
            <en-table-column label="面值" prop="mallCoupon.faceValue" width="200"></en-table-column>
            <en-table-column label="满减金额" prop="mallCoupon.lowerLimitAmount" width="120"></en-table-column>
            <en-table-column label="折扣">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                {{ row.mallCoupon?.type?.discountMethod.code === 'P' ? row.mallCoupon.discountValue : 0 }}
              </template>
            </en-table-column>
            <en-table-column label="抵扣金额" width="120">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                {{ row.mallCoupon?.type?.discountMethod.code === 'F' ? row.mallCoupon.discountValue : 0 }}
              </template>
            </en-table-column>
            <en-table-column label="项目" prop="mallCoupon.maintenance.name"></en-table-column>
            <en-table-column label="配件" prop="mallCoupon.goods.name"></en-table-column>
            <en-table-column label="有效期类型" width="120">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                <select-maintain
                  v-model="row.expireType"
                  :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['EXPTYPE']) }"
                  :props="{ label: 'description', value: '' }"
                  value-key="code"
                  @change="form.mallCombos.mallComboCoupons.expireType.change(row)"
                ></select-maintain>
              </template>
            </en-table-column>
            <en-table-column label="有效期" width="200">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                <en-date-picker
                  v-if="row.expireType?.code === 'F'"
                  v-model:model-value="row.startDate"
                  v-model:end="row.endDate"
                  class="w-full"
                ></en-date-picker>
                <en-input v-if="row.expireType?.code === 'D'" v-model="row.validityDays"></en-input>
              </template>
            </en-table-column>
            <en-table-column label="本次发放总量" prop="totalCount" width="120"></en-table-column>
            <en-table-column label="单次领取量" prop="count" width="120">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                <en-input-number v-model="row.count" :min="1" class="w-full"></en-input-number>
              </template>
            </en-table-column>
            <en-table-column label="固定核销天数" prop="count" width="150">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                <en-input-number
                  v-model="row.periodDays"
                  :min="1"
                  :precision="0"
                  :disabled="row.count && row.count > 1"
                  class="w-full"
                ></en-input-number>
              </template>
            </en-table-column>
            <en-table-column label="固定核销次数" prop="count" width="150">
              <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
                <en-input-number
                  v-model="row.countPerInstance"
                  :min="1"
                  :precision="0"
                  :disabled="row.count && row.count > 1"
                  class="w-full"
                ></en-input-number>
              </template>
            </en-table-column>
          </en-table>
        </div>

        <div class="mt-5 flex justify-end">
          <en-button type="primary" @click="form.mallCombos.delete.click(index)">删除套餐</en-button>
        </div>
      </div>
    </div>

    <template #footer>
      <en-button type="primary" @click="mallCombos.footer.add.click">添加套餐</en-button>
      <button-ajax :method="mallCombos.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const mallComboInit = <P extends Partial<EnocloudMallDefinitions['MallComboDto']>>(props?: P): EnocloudMallDefinitions['MallComboDto'] => {
  return Object.assign({ mallComboCoupons: [] }, props) as any
}

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallGroupPurchaseDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible && this.data?.id) {
          this.form.data.id = this.data?.id
          this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          name: [{ required: true, message: '请输入活动名称' }],
          intro: [{ required: true, message: '请输入活动简介' }],
          numberOfPeople: [{ type: 'number', required: true, message: '请输入拼团人数' }],
          'sendingCouponInstanceRedPackAllowed.code': [{ required: true, message: '请选择是否支持卡券赠送' }],
          'refundable.code': [{ required: true, message: '请选择是否支持退款' }],
          deduction: [{ type: 'number', required: true, message: '请输入退款扣除比例' }],
          'mallCombo.id': [{ type: 'number', required: true, message: '请选择套餐名称' }],
          count: [{ required: true, message: '请输入最少购买份数' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/mall/group/purchase/:groupPurchaseId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/mall/group/purchase',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/mall/group/purchase',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          merchantReceiver: {
            change() {}
          },
          customer: {
            change() {}
          },
          mallCombos: {
            add: {
              click() {
                this.mallCombos.visible = true
              }
            },
            delete: {
              click(index: number) {
                this.form.data.mallCombos?.splice(index, 1)
              }
            },
            mallComboCoupons: {
              add: {
                change(index: number, value: EnocloudMallDefinitions['MallCouponDto']) {
                  this.form.data.mallCombos?.[index].mallComboCoupons?.unshift({ mallCoupon: value })
                }
              },
              delete: {
                click(index: number, innerIndex: number) {
                  this.form.data.mallCombos?.[index].mallComboCoupons?.splice(innerIndex, 1)
                }
              },
              expireType: {
                change(row: EnocloudMallDefinitions['MallComboCouponDto']) {
                  row.startDate = ''
                  row.endDate = ''
                  row.validityDays = 0
                }
              }
            }
          },
          mallGroupPurchaseRewards: {
            add: {
              click() {
                this.form.data.mallGroupPurchaseRewards ??= []
                this.form.data.mallGroupPurchaseRewards.unshift({})
              }
            },
            delete: {
              click(index: number) {
                this.form.data.mallGroupPurchaseRewards?.splice(index, 1)
              }
            },
            expireType: {
              change(row: EnocloudMallDefinitions['MallComboCouponDto']) {
                row.startDate = ''
                row.endDate = ''
                row.validityDays = 0
              }
            }
          },
          mallGroupPurchaseReservations: {
            add: {
              click() {
                this.form.data.mallGroupPurchaseReservations ??= []
                this.form.data.mallGroupPurchaseReservations.unshift({})
              }
            },
            delete: {
              click(index: number) {
                this.form.data.mallGroupPurchaseReservations?.splice(index, 1)
              }
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form[this.form.data.id ? 'update' : 'submit']()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      },
      mallCombos: {
        visible: false,
        children: {
          footer: {
            add: {
              click() {
                this.form.data.mallCombos ??= []
                this.form.data.mallCombos.unshift(mallComboInit())
              }
            },
            confirm: {
              click() {
                this.mallCombos.visible = false
              }
            }
          }
        }
      }
    }
  }
})
</script>
